<template>
	<navBar />
	<div class="body">
        <div class="notification-container box-shadow p-4" style="margin-top:80px; border-radius: 8px">
            <div class="justify-content-between align-center d-flex">
                <label class="fs-3 text-dark">Notifications</label>
                <b-dropdown
                    right
                    variant="black"
                    toggle-class="three-dots"
                    menu-class="dropdown-menu-end"
					>
                    <template v-slot:button-content>
                        <i class="mdi mdi-dots-horizontal fs-3" style="margin-top:-50px"></i>
                    </template>
                    <!-- item-->
                    <div class="px-2">
                        <button class="dropdown-item text-dark d-flex">
                            <i class="bx bx-check fs-3"></i>
                            <span class="mx-4">Mark all as read</span>
                        </button>
                    </div>
                </b-dropdown>
            </div>
            <div class="notif-filter">
                <button class="selected-button mx-1">All</button>
                <button class="unselected-button mx-1">Unread</button>
            </div>
            <label class="fs-4 text-dark mt-4">Recent</label>
            <div class="notifications">
                <div class="cursor-pointer" v-for="data,index in this.data.list" :key="index">
                    <div v-if="data.type" :class="data.status == 0 ? 'bg-light': ''" class="d-flex p-2 mb-2 rounded pos-relative notif-box" @click="view(data)">
                        <div class="notif-pic">
                            <img v-if="!data.user_dp" src="@/assets/images/profiles/default.png" height="50" width="50" class="notif-profile"/>
                            <img v-if="data.user_dp" :src="`${image_api}/${data.user_dp}`" height="50" width="50" class="notif-profile"/>
                            <i v-if="data.type == 'comment' || data.type == 'subComment'" class="message-icon bx bxs-message-dots bg-success text-white fs-4" ></i>
                            <i v-if="data.type == 'message'" class="message-icon bx bxs-message-rounded bg-success text-white fs-4" ></i>
                        </div>
                        <div class="mx-3">
                            <h6 class="mb-1 mt-1 fw-bold notif-text">
                                {{data.username}} 
                                <span class="text-muted fw-lighter" v-if="data.type == 'comment' || data.type == 'subComment'">commented at your post. </span>
                                <span class="text-muted fw-lighter" v-if="data.type == 'message'">messaged you. </span>
                                <span class="fw-bold">{{data.text}}</span>
                            </h6>
                            <span class="text-muted">{{`${dateOnly(data.created_at)} at ${timeOnly(data.created_at)}`}}</span>
                        </div>
                        <span class="chat-circle" v-if="data.status == 'unread'"></span>
                    </div>
                    <div v-if="data.reaction_type" :class="data.status == 0 ? 'bg-light': ''" class="d-flex p-2 mb-2 rounded pos-relative notif-box" @click="view(data)">
                        <div class="notif-pic">
                            <img v-if="!data.user_dp" src="@/assets/images/profiles/default.png" height="50" width="50" class="notif-profile"/>
                            <img v-if="data.user_dp" :src="`${image_api}/${data.user_dp}`" height="50" width="50" class="notif-profile"/>
                            <i v-if="data.reaction_type == 'haha'" class="message-icon bx bxs-happy-alt bg-info text-white fs-4" ></i>
                            <i v-if="data.reaction_type == 'like'" class="message-icon bx bxs-like bg-info text-white fs-4" ></i>
                            <i v-if="data.reaction_type == 'heart'" class="message-icon bx bxs-heart bg-danger text-white fs-4" ></i>
                            <i v-if="data.reaction_type == 'wow'" class="message-icon bx bxs-shocked bg-primary text-white fs-4" ></i>
                        </div>
                        <div class="mx-3 ">
                            <h6 class="mb-1 mt-1 fw-bold notif-text">{{data.username}} <span class="text-muted fw-lighter">reacted at your post. </span>
                                <span class="fw-bold ">{{data.text}}</span>
                            </h6>
                            <span class="text-muted">{{`${dateOnly(data.created_at)} at ${timeOnly(data.created_at)}`}}</span>
                        </div>
                        <span class="chat-circle" v-if="data.status == 'unread'"></span>
                    </div>
                </div>
            </div>
        </div>
        <!-- MODALS -->
        <b-modal 
            v-model="post_modal" 
            no-close-on-backdrop
            hide-footer
            size="md"
            title="Create Post" 
            centered
            >
            <div class="d-flex">
                <div class="users">
                    <i class="bx bx-user post-profile"></i>
                </div>
                <div class="post mx-3">
                    <span style="color: #050505">Test Name</span>
                    <p style=" color:#999999; font-size: 14px;">Public</p>
                </div>
            </div>
            <textarea class="form-control mb-3 write-something-input" placeholder="Write something..." v-model="pl_post.text">
            </textarea>
            <input
                type="file"
                ref="uploadFile"
                multiple 
                class="form-control mb-3" 
                @change="handleFileSelect" accept="image/*,video/*"
                />
            <div class="text-end mt-3">
                <b-button class="mx-1 w-100" variant="info" @click="addPost()">Post</b-button>
            </div>
        </b-modal>
        <b-modal 
            v-model="search_modal" 
            no-close-on-backdrop
            hide-footer
            size="md"
            title="Search" 
            centered
            >
            <div class="d-flex mb-3">
                <div class="search w-100">
                    <i class="bx bx-search"></i>
                    <input
                        type="text"
                        placeholder="Search this group"
                        class="form-control"
                    />
                </div>
            </div>
            <h5>Recent Searches</h5>
            <div>
                <div class="d-flex p-3 recent-searches-button" v-for="data,i in 5" :key="i">
                    <i class="bx bx-time-five fs-4"></i>
                    <span class="mx-2">Bags</span>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import navBar from "@/components/nav-bar.vue";
import '@/assets/css/user-dashboard.css';
import { mapActions,mapGetters } from "vuex";
import formatter from '@/mixins/formatter';
// import Swal from 'sweetalert2';
export default {
    data() {
        return {
            members: '4.2k',
			data: {
				list: [],
				links: [],
				current_page: 1,
				per_page: 1,
				last_page: 1,
			},
            pl_post:{
                text: null,
            },
            content: '',
            post_modal: false,
            search_modal: false,
            image_api: process.env.VUE_APP_BACKEND_API_IMAGES,
            mediaInput: null,
            selectedMedia: [],
            mediaJSON: null,
            comment: null,
        };
    },
    mixins:[formatter],
	components: {
		navBar
	},
	computed: {
        ...mapGetters('auth', ['profile']),
	},
    methods:{
		...mapActions("posts", {
			createPost: "createPosts",
			getList: "getPosts",
		}),
		...mapActions("userNotification", {
			// notificationList: "getListJSON",
			notificationList: "getList",
			readPost: "readNotification",
		}),
        async initList(p) {
			var pl = {
				page: p,
                // owner: this.profile.id
                user_id: this.profile.id

			};
			const data = await this.notificationList(pl);
			this.data.list = data.data;
			this.data.links = data.links;
			this.data.current_page = data.current_page;
			this.data.per_page = data.per_page;
			this.data.last_page = data.last_page;
		},
        // RESPONSE MEDIA
        parsedMedia(media) {
            try {
                return JSON.parse(media);
            } catch (error) {
                return [];
            }
        },
        getMediaUrl(filename) {
            return `${this.image_api}/${filename}`;
        },
        // gotoDashboard(data){
        //     console.log(data)
        //     this.$router.push({
        //         path:"/",
        //         query: {
        //             id: data._post_id,
        //         },
        //     })
        // }
        async view(data){
            var pl = {
                status: 'read',
                id: data._id
            }
            await this.readPost(pl);
            if(data.type == 'message'){
                this.$router.push({
                    path:"/messages",
                })
            }else if(data.type == 'comment' || data.type == 'subComment' || data.reaction_type != null){
                this.$router.push({
                    path:"/notifications-view",
                    query: {
                        id: data._post_id,
                    },
                })
            }
        },
    },
    mounted(){
        setTimeout(() => {
            this.initList(1)
        }, 1000);
    }
};
</script>